<template lang="">
  <div>
    <!-- Storage {{ storage }} -->
    <b-row class="match-height">
      <b-col>
        <b-card
          v-if="isStorageBooked(storage).value"
          bg-variant="success"
          text-variant="white"
        >
          <b-card-title class="text-white">
            Vermietet oder Reserviert
          </b-card-title>
          <b-card-text>
            <!-- {{ lastestOrder()[0].customer.firstName }}
            {{ lastestOrder()[0].customer.lastName }} -->
          </b-card-text>
        </b-card>
        <b-card v-else bg-variant="danger" text-variant="white">
          <b-card-title class="text-white"> Nicht vermietet </b-card-title>
          <b-card-text>
            Diese Lagereinheit ist aktuell nicht vermietet!
          </b-card-text>
        </b-card>
      </b-col>
      <b-col>
        <b-card>
          <b-card-title> Status </b-card-title>
          <b-card-text>
            <span
              v-for="(status, index) in storage.storage_statuses"
              :key="index"
              class="pl-1"
            >
              <b-badge
                pill
                :variant="
                  getBadgePillVariant(storage.storage_statuses[index].status)
                "
                >{{ storage.storage_statuses[index].status }}</b-badge
              >
            </span>
            <b>Zahlenschloss</b>
            <div>{{ storage.lockPin }}</div>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col>
        <statistic-card-vertical
          color="primary"
          icon="HashIcon"
          :statistic="storage.storageIdent"
          statistic-title="Lager ID"
        /> </b-col
      ><b-col>
        <statistic-card-vertical
          color="primary"
          icon="AlignJustifyIcon"
          :statistic="storage.name"
          statistic-title="Name"
        />
      </b-col>
      <b-col>
        <b-card title="Beschreibung">
          <b-card-text>
            {{ storage.description }}
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col>
        <statistic-card-vertical
          color="primary"
          icon="MapIcon"
          :statistic="storage.location.name"
          statistic-title="Standort"
        />
      </b-col>
      <b-col>
        <statistic-card-vertical
          color="primary"
          icon="LayoutIcon"
          :statistic="storage.storageSpace"
          statistic-title="Lagerfläche m²"
        /> </b-col
      ><b-col>
        <statistic-card-vertical
          color="primary"
          icon="CodepenIcon"
          :statistic="storage.storageVolume"
          statistic-title="Volumen m³"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BCardText,
  BCardTitle,
  BBadge,
} from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import storageHelper from '@/helper/storageHelper'

export default {
  setup() {
    const { isStorageBooked, isStorageRented } = storageHelper()

    return { isStorageBooked, isStorageRented }
  },
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BCardText,
    BCardTitle,
    StatisticCardVertical,
    BBadge,
  },
  props: {
    storage: {
      type: Object,
      required: true,
    },
  },
  methods: {
    lastestOrder() {
      let max = 0
      this.storage.order_items.forEach(orderItem => {
        if (orderItem.order > max) {
          max = orderItem.order
        }
      })

      return this.$store.getters.orderbyID(Number(max))
    },
    getBadgePillVariant(status) {
      if (status === 'In Reinigung') {
        return 'warning'
      }
      if (status === 'Beschädigt') {
        return 'danger'
      }
      if (status === 'Stillgelegt') {
        return 'dark'
      }
      if (status === 'Reparatur') {
        return 'primary'
      }
      if (status === 'Umbau') {
        return 'secondary'
      }

      return 'success'
    },
  },
}
</script>
<style lang=""></style>
