<template lang="">
  <div>
    <b-row class="match-height">
      <b-col
        cols="12"
        xl="12"
        lg="12"
        md="12"
      >
        <storage-info-cards :storage="storagesById" />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        cols="12"
        xl="12"
        lg="12"
        md="12"
      >
        <storage-orders :storage-orders="storagesById.order_items" />
      </b-col>
    </b-row>
    <!-- <b-row class="match-height">
      <b-col cols="12" xl="12" lg="12" md="12">
        <orders-detail :orders="orderbyID" />
      </b-col>
    </b-row> -->
  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import StorageInfoCards from './StoragesViewInfoCards.vue'
import StorageOrders from './StoragesOrders.vue'

export default {
  components: {
    BRow,
    BCol,
    StorageInfoCards,
    StorageOrders,
  },
  props: ['id'],
  computed: {
    storagesById() {
      return this.$store.getters.storageById(Number(this.id))
    },
  },
}
</script>
<style lang=""></style>
