<template lang="">
  <!-- {{ storageEntries }} -->
  <b-card no-body>
    <b-table
      striped
      hover
      :items="storageOrders"
      :fields="fields"
      :filter="filter"
      responsive
      @row-clicked="onRowClicked"
    >
      <template #cell(price)="data"> {{ data.item.price }} € </template>
      <template #cell(discount)="data">
        {{ getFormattedDiscount(data.item.discount) }} €
      </template>
      <template #cell(rentStart)="data">
        {{ getFormattedDate(data.item.rentStart) }}
      </template>
      <template #cell(rentEnd)="data">
        <td :class="classChanger(data.item.rentEnd)">
          {{ getFormattedDate(data.item.rentEnd) }}
        </td>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import {
  BCard,
  BTable,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BPagination,
  BBadge,
  BLink,
} from 'bootstrap-vue'
import helper from '@/helper/helper'
import tableHelper from '../../Table+Helper'

export default {
  setup() {
    const { getFormattedDate, getFormattedDiscount } = helper()

    const {
      perPageOptions,
      perPage,
      filter,
      totalRows,
      currentPage,
      refListTable,
      dataMeta,
      refetchData,
    } = tableHelper()

    return {
      perPageOptions,
      perPage,
      filter,
      totalRows,
      currentPage,
      refListTable,
      dataMeta,
      getFormattedDate,
      refetchData,
      getFormattedDiscount,
    }
  },
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BPagination,
    BBadge,
    BLink,
  },
  props: {
    storageOrders: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'order',
          label: 'Buchungsnummer',
        },
        {
          key: 'price',
          label: 'Preis',
        },
        {
          key: 'discount',
          label: 'Rabatt',
        },
        {
          key: 'rentStart',
          label: 'Mietstart',
        },
        {
          key: 'rentEnd',
          label: 'Mietende',
        },
      ],
    }
  },
  methods: {
    onRowClicked(item) {
      this.$router.push(`/orders-view/${item.order}`)
    },
    isRentActive(rentEndDate) {
      return Date.now() < new Date(rentEndDate).getTime()
    },
    classChanger(rentEndDate) {
      let theClass = 'notRented'
      if (this.isRentActive(rentEndDate)) {
        theClass = 'rented'
      }
      return theClass
    },
  },
}
</script>
<style>
.rented {
  background: #c3efd7;
  border-radius: 0px !important;
}

.notRented {
  background: #f9cfcf;
  border-radius: 0px !important;
}
</style>
